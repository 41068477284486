@import 'styles/variables';

.productTitle {
  align-items: normal;
  font-weight: $boldFontWeight;
}

.productTable {
  width: 100%;
}

.anyRow {
  padding-right: 0;
  border-bottom: none;

  &:last-child {
    border-bottom: 1px solid $borderAndSeparatorsColor;

    > .sectionCell {
      border-bottom: none;
    }
  }
}

.productRow {
}

.sectionRow {
  padding-right: 0;
  border-bottom: 0;
}

.sectionCell {
  border-bottom: 1px dashed $borderAndSeparatorsColor;
}

.checkbox {
  padding: 0 !important;
  width: 24px;
}

.userRole {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
  margin-left: 12px;
}

.userRoleLoading {
  color: $primary40;
}
