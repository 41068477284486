@import 'styles/variables';
@import 'styles/colorPalette';
@import 'components/TextInput/TextInput.module';

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 24px;
  width: 100%;
}

.labelWithLink {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.label {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
}

.navLink {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $defaultLinkColor;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: $defaultLinkHoverColor;
  }
}

@mixin inputBox {
  border: 1px solid $primary10;
  border-radius: $borderRadiusDefault;
  padding: 16px 20px;
  height: 52px;
}

.input {
  @include inputBox;
  @include inputStyles;
  display: flex;
  align-items: center;
  margin-top: 8px;
  background: $surfacesPrimary;
  width: 100%;

  &:hover {
    border: 1px solid $primary20;
  }

  &:focus {
    border: 1px solid $secondary40;
  }

  &::placeholder {
    color: $subtextColor;
    opacity: 1;
  }

  &:disabled,
  &__disabled {
    border: 1px solid $borderAndSeparatorsColor;
    cursor: default;
    background-color: $surfacesLight;
    color: $primary80;
  }

  &.inputError {
    border: 1px solid $red100Base;
  }
}

.inputWithRightNeighbour {
  @extend .input;
  border-radius: 4px 0 0 4px;
}

.inputWithLeftNeighbour {
  @extend .input;
  border-radius: 0 4px 4px 0;
}

.inputWithLeftPadding {
  @extend .input;
  padding-left: 56px;
}

.inputWithRightPadding {
  @extend .input;
  padding-right: 40px;
}

.textArea {
  @extend .input;
  height: 104px;
}

.errorNotification {
  color: $defaultErrorColor;
  margin-top: 4px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}
