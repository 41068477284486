@import 'styles/variables';
@import 'styles/mixins';

.input {
  flex: 1 0 calc(50% - 30px);

  &:nth-child(odd) {
    margin-right: 30px;
    max-width: calc(50% - 30px);
  }
}

.inputContainer {
  width: auto;
  display: flex;
  flex-flow: row wrap;
}

.buttonContainer {
  margin-top: 36px;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

@import 'styles/variables';
.container {
  width: 100%;
  padding: 0 55px 48px;
}

.buttonsContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 36px;
}

.inputWrapper {
  display: block;
  width: 100%;
  height: 80px;
  background-color: rgba(221, 222, 228, 0.1);
  border: 1px dashed $primary20;
  border-radius: 4px;
  margin-top: 8px;
  transition: 0.25s border ease-in-out;
}

.dragOverInputWrapper {
  @extend .inputWrapper;
  border: 1px dashed $secondary100Base;
}

.fileInfoContainer {
  @extend .inputWrapper;
  border: 1px solid $primary20;
  padding: 18px 18px 18px 33px;
  display: flex;
  align-items: center;
  background-color: inherit;
}

.fileInput {
  display: none;
}

.fileInfo {
  margin-left: 24px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.fileDelete {
  cursor: pointer;
  stroke: $red100Base;
  align-self: flex-start;
  margin-left: auto;
  min-width: 20px;
  min-height: 20px;
}

.fileName {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h5FontSize;
  line-height: $h5LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary100Base;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fileFormat {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $subtextColor;
  text-transform: uppercase;
}

.textContainer {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  display: flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $subtextColor;
  width: 100%;
  height: 100%;
}

.link {
  color: $secondary100Base;
  cursor: pointer;
  margin: 0 3px;

  &:hover {
    color: $defaultLinkHoverColor;
  }
}

.fileImage {
  min-width: 32px;
}

.dropzoneLabel {
  margin-top: 24px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
}

.link {
  color: $secondary100Base;
  cursor: pointer;
  margin: 0 3px;

  &:hover {
    color: $defaultLinkHoverColor;
  }
}
