@import 'styles/variables';

.description {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
}

.buttonsContainer {
  display: flex;
  margin-top: 36px;
}

.goBackButton {
  margin-left: 12px;
}
