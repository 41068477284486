@import 'styles/variables';

.infoPanel {
  padding: 22px 30px 22px 30px;
  background: $surfacesLight;
  border-radius: 6px;
  margin-top: 24px;
}

.infoPanelHeader {
  margin-bottom: 22px;
}

.infoPanelContent {
  display: flex;
}

.infoPanelColumn {
  margin-right: 30px;
  width: calc(100% / 3);

  & > div:first-child {
      margin-bottom: 14px;
  }
}

.infoPanelRow {
  display: flex;
  column-gap: 25px;
  padding: 14px 0;
}

