@import 'styles/variables';

.container {
  background-color: $surfacesLight;
}

.rowsContainer {
  display: flex;
  flex-direction: column;
}

.addCardButton {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: $secondary10;
  }

  & path {
    stroke: $secondary100Base;
  }
}

.row {
  position: relative;
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 24px;

    .addCardInNewRowButton {
      display: none;
    }
  }

  .addCardInSameRowButton {
    position: absolute;
    right: -32px;
  }

  .addCardInNewRowButton {
    position: absolute;
    bottom: -40px;
  }
}

.variablesConfigurationCard {
  width: 100%;

  &:not(:last-child) {
    margin-right: 30px;
  }
}

.addVariableButton {
  width: 200px;
}
