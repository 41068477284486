@import 'styles/colorPalette';

.codeInput {
  padding-top: 12px;
}

.submitButton {
  margin-top: 36px;
}

.resendCodeTimeout {
  margin-top: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
}

.resendCodeTimeoutSeconds {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: inline;
  color: $primary100Base;
}
