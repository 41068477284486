@import 'styles/variables';

.buttonTopMargin {
  margin-top: 24px;
  width: 100%;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  padding-bottom: 12px;
  color: $primary100Base;
}

.checkboxesMarginLeft {
  margin-left: 2px;
}
