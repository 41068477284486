@import 'styles/variables';
@import 'styles/colorPalette';
@import 'styles/mixins';

$transitionDuration: 0.25s;

.historyHeader {
  width: 100%;
  padding-top: 12px;

  &__title {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
  }
}

.historySearchContainer {
  margin: 24px 24px 0 0;
}

.table {
  margin-top: 18px;
}

.batchHistoryNoData {
  margin-top: 60px;
}

.batchHistoryNoDataTitle {
  margin-top: 36px;
}
