@import 'styles/variables';

.contentMessage,
.contentSubMessage {
  color: $primary80;
  font-size: 14px;
  font-family: $fontFamilyMonsterrat;
  line-height: 20px;
  font-weight: 500;
}

.contentSubMessage {
  margin-top: 24px;
}

.table {
  margin-top: 24px;
}

.buttonsContainer {
  margin-top: 36px;
}

.submit {
  margin-right: 12px;
}
