@import 'styles/variables';
@import 'components/Auth/Variables.module.scss';
@import 'styles/mixins';

$formWidth: 480px;

.container {
  width: 100%;
  display: flex;
}

.formContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $white100Base;
  justify-content: space-between;
}

.formContainerHeader {
  height: auto;
  width: 100%;
}

.formContainerBody {
  padding-top: 36px;
}

.formContainerBodyTitleContainer {
  height: auto;
  width: 100%;
  margin-bottom: 36px;
}

.formContainerBodyTitle {
  font-weight: bold;
  font-family: $fontFamilyMonsterrat;
  color: $primary100Base;
  white-space: pre-wrap;
}

.formContainerBodyImageTitle {
  margin-bottom: 36px;
}

.formContainerBodySubTitle {
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: $primary80;
}

.waveContainer {
  width: 100%;
  background: url(/img/pattern.svg) $surfacesSidebar;
  position: relative;
}

.footer {
  margin-top: 60px;
}

.backLink {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultLinkColor;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: $defaultLinkHoverColor;

    & > svg {
      stroke: $defaultLinkHoverColor;
    }
  }

  & > svg {
    stroke: $defaultLinkColor;
  }
}

@media (max-width: $mobileMaxWidth) {
  .container {
    flex-direction: column-reverse;
    overflow-y: auto;
    height: auto;
    min-height: 100vh;

    .waveContainer {
      min-height: 20vh;
      background-size: 50vw;
    }

    .formContainer {
      overflow-y: hidden;
      flex: 1;
    }

    .formContainerBody,
    .formContainerHeader {
      padding-left: 24px;
      padding-right: 24px;
    }

    .formContainerBody {
      padding-top: 32px;
    }

    .formContainerHeader {
      display: none;
    }

    .lightLogo {
      position: absolute;
      bottom: 36px;
      left: 24px;
    }

    .footer {
      padding: 22px 24px;
    }

    .formContainerBodyTitle {
      font-size: 28px;
      line-height: 36px;
    }
  }

  .notifications {
    $padding: 24px;
    $left: $padding;

    left: $left;
    width: calc(100% - #{$left + $padding});
  }
}

@media (min-width: $desktopMinWidth) {
  .container {
    flex-direction: row;
    overflow-y: hidden;
    height: 100vh;

    .waveContainer {
      height: 100%;
      position: relative;
    }

    .formContainer {
      @include overflowOverlayY;
    }

    .formContainerHeader,
    .formContainerBody {
      padding-left: 55px;
      padding-right: 55px;
    }

    .formContainer {
      padding-top: 32px;
      min-width: $formWidth;
      max-width: $formWidth;
    }

    .lightLogo {
      display: none;
    }

    .formContainerBodyTitle {
      font-size: 36px;
      line-height: 44px;
    }
  }

  .notifications {
    $padding: 55px;
    $left: $formWidth + $padding;

    left: $left;
    width: calc(100% - #{$left + $padding});
  }
}
