@import 'styles/variables';
@import 'styles/mixins';

.container {
  position: relative;
  width: 980px;
  padding: 36px 55px 60px 55px;
}

.tabContainer {
  padding-top: 36px;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  h2 {
    @include headerTitle;
  }
}

.closeButton {
  position: absolute;
  top: 36px;
  right: 36px;
  cursor: pointer;

  & path {
    stroke: $defaultTextColor;
  }
}

.titleContainer {
  display: flex;
}

.emailButton {
  margin: 0 4px 0 12px;
  & path {
    fill: $primary100Base;
    stroke: none;
  }

  &:hover {
    & path {
      fill: $secondary100Base;
      stroke: none;
    }
  }

  &:disabled {
    & path {
      fill: $primary40;
      stroke: none;
    }

    &:hover {
      & path {
        fill: $primary40;
        stroke: none;
      }
    }
  }
}
