.overflowedText {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    overflow: auto;
    white-space: normal;
    text-overflow: clip;
    word-break: break-all;
  }
}
