@import 'components/Button/ButtonVariables';

.button {
  padding: $mdBtnWithIconPadding;
  justify-content: center;
}

.actionItem {
  display: flex;
  align-items: center;

  padding-right: 4px;
}

.checkIcon {
  margin: -50% 0 -50% auto;
}

.popupRoot {
  margin-top: 2px;
}
