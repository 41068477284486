@import 'styles/variables';

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.tabContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 60px;

  .iconContainer {
    display: flex;
    justify-content: center;
  }

  .successTitle {
    margin-top: 36px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h5FontSize;
    line-height: $h5LineHeight;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #000000;
  }

  .description {
    margin-top: 8px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $defaultTextColor;
  }
}
