.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;

  & > div {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}
