@import 'styles/variables';

.container {
  margin-top: 24px;
}

.inputContainer {
  display: flex;
}

.title {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
}

.firstTextInput {
  padding-top: 0;

  & input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.secondTextInput {
  padding-top: 0;
  margin-left: -1px;

  & input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
