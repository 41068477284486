@import 'styles/variables';

.existingBorrowerForm {
  padding-top: 24px;
}

.lockedCustomerMessage {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: 22px;
  line-height: 28px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary100Base;
  mix-blend-mode: normal;
  margin-bottom: 24px;
}

.footer {
  margin-top: 24px;

  &.locked {
    margin-top: 48px;
  }

  .addIntermediaryCheckbox {
    margin-top: 12px;
  }
}
