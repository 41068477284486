@import 'styles/variables';

.overviewContainer {
  padding-bottom: 16px;
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.overviewHeader {
  display: flex;
  align-items: center;
  & > div {
    display: flex;
    cursor: pointer;
    & > p {
      font-style: normal;
      font-size: $pFontSize;
      line-height: $pLineHeight;
      font-weight: $normalFontWeight;
      color: $secondary100Base;
      margin-left: 7px;
      transition: 0.25s color ease-in-out;
    }
    & > button {
      border: 0;
      background-color: transparent;
      cursor: pointer;
      outline: none;
      padding: 0;
      width: 24px;
      height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > svg {
        stroke: $secondary100Base;
        cursor: pointer;
        transform: rotate(180deg);
        transition: transform 0.25s ease-in-out, stroke 0.25s ease-in-out;
      }

      &.isOpen {
        & > svg {
          transform: rotate(360deg);
        }
      }
    }

    &:hover {
      & > p {
        color: $secondary120;
      }

      & > button {
        & > svg {
          stroke: $secondary120;
        }
      }
    }
  }
}

.overviewContent {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 0;

  &Opened {
    height: auto;
  }

  & > h6 {
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $headerTextColor;
    margin-bottom: 16px;
    width: 100%;
  }

  & > ul {
    margin: 0 0 24px 5px;
    list-style-type: '•';
    list-style-position: outside;

    & > li {
      & > p {
        margin-left: 12px;
        font-style: normal;
        font-weight: $normalFontWeight;
        font-size: $pFontSize;
        line-height: $pLineHeight;
        color: $defaultTextColor;
      }
    }
  }

  & > p {
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $defaultTextColor;
    margin-bottom: 24px;
  }
}

.overviewExpanded {
  max-height: 0;
  height: auto;
  transition: max-height 0.5s ease-in-out, margin-top 0.5s ease-in-out;
  overflow: hidden;
  margin-top: 0;
}

.overviewCollapsed {
  @extend .overviewExpanded;
  margin-top: 24px;
  max-height: 700px;
}
