.table {
  width: 100%;

  .totalColumnHeadCell {
    justify-content: flex-end;
  }
}

.tableHeadCell {
  padding: 10px 20px 10px 12px;
}
