@import 'styles/variables';

.infoPanelContentItemInner {
  display: flex;
  align-items: flex-start;
  padding: 14px 0;
  border-top: 1px solid $primary10;
  min-height: 50px;
}

.infoPanelLabel {
  color: $primary40;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  min-width: 115px;
  height: 20px;
  display: flex;
  align-items: center;
}

.infoPanelContentItem {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $defaultTextColor;

  & a {
    color: $defaultTextColor;
    transition: 0.25s color ease-in-out;

    &:hover {
      color: $defaultLinkColor;
    }
  }
}
