@import 'styles/variables';

.container {
  min-height: 100%;
  width: 100%;
}

.header {
  & h2 {
    color: $headerTextColor;
    font-size: $h2FontSize;
    line-height: $h2LineHeight;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-family: $fontFamilyMonsterrat;
  }
}

.body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 13px;
}

.itemContainer {
  width: 48%;
  box-sizing: border-box;
}

.footer {
  margin-top: 36px;
}
