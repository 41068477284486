@import 'styles/variables';

.inputContainer {
  margin-top: 24px;
  &__label {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    color: $headerTextColor;
  }

  &__error {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $defaultErrorColor;
    margin-top: 4px;
  }
}

.inputContainerWithDataType {
  @extend .inputContainer;
  width: 100%;
}

.inputError {
  border: 1px solid $defaultErrorColor;
}

.select {
  position: relative;
  outline: none;
  border-radius: 4px;
}

.selectControl {
  margin-top: 8px;
  height: 52px;
  border: 1px solid $primary10;
  border-radius: 4px;
  padding: 16px 20px;
  cursor: pointer;
  width: 100%;
  background-color: $white100Base;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    border: 1px solid $primary20;
  }

  .clearIcon {
    display: none;
  }

  & input {
    width: 100%;
    border: 0;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $headerTextColor;
    outline: none;
    cursor: pointer;
    &::placeholder {
      color: $placeholderTextColor;
    }
  }

  & .inputValueContainer {
    display: flex;
    width: 100%;
    margin-right: 10px;
  }

  & .inputLabelValueContainer {
    @extend .inputValueContainer;
    margin-right: 0;
  }

  & .emptyInput {
    width: 0;
    min-height: 0;
    height: 0;
    padding: 0;
  }

  &.inputFocus {
    border: 1px solid $secondary40;

    .hasError {
      border: 1px solid $red100Base;
    }

    &:hover > input {
      cursor: text;
    }
  }

  & svg {
    stroke: $headerTextColor;
    min-width: 24px;
  }
}

.selectControlWithRightNeighbour {
  @extend .selectControl;
  border-radius: 4px 0 0 4px;
}

.selectControlWithLeftNeighbour {
  @extend .selectControl;
  border-radius: 0 4px 4px 0;
}

.highlightSelectControl {
  @extend .selectControl;
  border: 1px solid $defaultErrorColor;

  &:hover,
  &.inputFocus {
    border: 1px solid $defaultErrorColor;
  }
}

.inputContainerWithRightNeighbour {
  @extend .inputContainer;
  border-radius: 4px 0 0 4px;
  width: 100%;
}

.inputContainerWithLeftNeighbour {
  @extend .inputContainer;
  border-radius: 0 4px 4px 0;
  width: 100%;
}

.disabledSelectControl {
  @extend .selectControl;
  background-color: $surfacesLight;
  cursor: default;
  &:hover {
    border: 1px solid $primary10;
  }
  & input {
    cursor: default;
    background-color: $surfacesLight;
  }
}

.optionsList {
  padding: 8px 0;
  position: absolute;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  z-index: 999;

  &__hidden {
    display: none;
  }
}

.option {
  padding: 10px 16px 10px 20px;
  cursor: pointer;
  width: 100%;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  & input {
    display: none;
  }

  &TextContent {
    flex-grow: 1;
  }

  &CheckMark {
    height: 24px;
    width: 24px;
  }

  .name {
    color: $headerTextColor;
  }

  &Content {
    display: flex;
  }

  &Active {
    background-color: $secondary10;
  }

  &Checked {
    color: $secondary100Base;
  }

  &Disabled {
    cursor: default;
    .name {
      color: $primary40;
    }
    &.optionActive {
      background: $primary6;
    }
  }
}

.branchName {
  display: flex;
  flex-direction: column;
}

.description {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  display: flex;
  align-items: flex-end;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $subtextColor;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.whiteLabel {
  color: $white100Base;
}

.defaultOption {
  @extend .option;
  color: $subtextColor;
}

div.inputLoader {
  width: auto;
}

.optionTextContent {
  margin-right: 5px;

  &.hasIcon {
    margin-left: 7px;
  }
}

.textLabel {
  visibility: hidden;
  font-family: $fontFamilyMonsterrat;
  position: absolute;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  padding-right: 3px;
}

.fullInputWidth {
  width: 90%;
}

.hiddenIconAfter {
  visibility: hidden;
}

.iconAfter {
  position: absolute;
  visibility: visible;
}

.inputIconContainer {
  position: relative;
  display: flex;
  align-self: stretch;
  align-items: center;
}

.clearIcon {
  position: absolute;
  right: 100%;
  top: 0;
  stroke: $primary80;
  fill: $primary80;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
  background: $surfacesPrimary;
  box-shadow: -5px 0 5px 0 $surfacesPrimary;
}

.selectInputWithValue {
  &:hover .clearIcon,
  .showClearIcon {
    display: block;
  }

  &:hover .loader:not(.fixedLoader) {
    right: 80px;
  }
}

.errorNotification {
  color: $defaultErrorColor;
  margin-top: 4px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

.optionContent {
  display: flex;
  align-items: center;
}

.loader {
  position: absolute;
  right: 50px;
}

.inputWithLabel {
  margin: 0 30px 0 8px;
}
