@import 'styles/variables';

.container {
  overflow: hidden;
  flex: 1 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-flow: column;
}

.actions {
  display: flex;

  & button {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 12px 55px;
  z-index: 1;
  border-top: 1px solid $primary20;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $subtextColor;
  flex: 0 0 auto;
  min-width: 1160px;
  box-shadow: 0 0 60px rgba(15, 35, 66, 0.2);
}

.exitButton {
  width: 127px;
}

.continueButton {
  width: 128px;
}
