@import 'styles/variables';

.noteTextContainerExpanded {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.noteTextContainerCollapsed {
  width: 100%;
  display: flex;
}

.noteTextCollapsed {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 80%;
}

.noteTextExpanded {
  max-width: 95%;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.controlButton {
  background-color: transparent;
  border: 0;
  padding: 0;
  white-space: nowrap;
  cursor: pointer;
  color: $secondary100Base;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-family: $fontFamilyMonsterrat;
  outline: 0;
}
