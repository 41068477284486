@import 'styles/variables';

.pageContainer {
  min-height: 100%;
  width: 100%;
}

.tabSwitch {
  margin: 24px 0 32px 0;
}

.pageLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
