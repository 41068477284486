@import 'styles/variables';
@import 'styles/colorPalette';
@import 'styles/mixins';

.variableLine {
  &:hover {
    button {
      opacity: 1;
    }
  }

  &__nameText {
    width: 100%;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    font-weight: $boldFontWeight;
    color: $headerTextColor;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-feature-settings: 'tnum' on, 'lnum' on;
  }

  &__systemNameText {
    @extend .variableLine__nameText;
    font-weight: $normalFontWeight;
    color: $defaultTextColor;
  }

  &__variableTypeText {
    @extend .variableLine__systemNameText;
  }

  &__dataTypeText {
    @extend .variableLine__systemNameText;
  }

  &__updatedText {
    @extend .variableLine__systemNameText;
  }

  &__descriptionText {
    @extend .variableLine__systemNameText;
  }

  &__options {
    @include buttonOptions;
  }

  &Highlighted {
    border: 1px solid $secondary100Base;
    background: $secondary10;
    height: 43px;
  }
}

.variableLineInactive {
  background-color: $primary2;
  & > div {
    & > div {
      & > p {
        color: $primary40;
      }
    }
  }
}

.creatorIcon {
  width: 28px;
  height: 28px;
  margin-right: 12px;
}
