.skeletonHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 48px 55px 27px 55px;
}

.skeletonTitle {
  & > div {
    &:first-child {
      margin-bottom: 2px;
    }
  }
}
