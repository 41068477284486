@import 'styles/variables';

.container {
  background: $orange10;
  border: 1px solid $orange100Base;
}

.innerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 30px;
}

.messageContent {
  width: 66%;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $primary80;
}

.title {
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-weight: $boldFontWeight;
  color: $orange100Base;
  margin-bottom: 4px;
}

.shareButton {
  min-width: 140px;
}
