@import 'components/TextInput/TextInput.module';

.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 16px 20px;

  &__disabled {
    cursor: default;
    background-color: $surfacesLight;
    color: $primary80;
  }
}

.phoneNumberInput {
  @include inputStyles;
  border: none;
  flex: 1;

  &:disabled {
    border: none;
  }
}
