@import 'components/NoItems/CommonVariables.module';

.container {
  overflow: hidden;
  flex: 1 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 55px 50px 55px;
  flex-flow: column;
}

.noItems {
  margin-top: $noDataTopOffset - 6px; // invalid product dashboard title layout

  .noItemsTitle {
    margin-top: 36px;
  }

  .noItemsButton {
    margin-top: 12px;
  }
}
