@import 'styles/variables';

.fields {
  display: grid;
  margin-top: 16px;
}

.field {
  display: flex;
  border-top: 1px solid $borderAndSeparatorsColor;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:last-child {
    border-bottom: 1px solid $borderAndSeparatorsColor;
  }

  & > div {
    width: 50%;
    padding: 14px 10px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
  }

  .fieldTitle {
    color: $primary40;
  }

  .fieldValue {
    color: $primary80;
  }
}
