@import 'styles/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 16px;
  color: $primary100Base;
}

.clearFilters {
  border: none;
  color: $secondary100Base;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  background-color: transparent;
  cursor: pointer;
  margin-left: 0;
  margin-right: auto;
  outline: none;
}

.closeButton {
  border: none;
  background-color: transparent;
  height: 36px;
  width: 36px;
  padding: 0;
  cursor: pointer;
  outline: none;

  & svg {
    width: 100%;
    height: auto;
    margin: -7px;

    & path {
      stroke: $defaultTextColor;
    }
  }
}
