@import 'styles/variables';

.input {
  display: flex;
  width: 100%;
  border-radius: 4px;
  border: 1px solid $borderAndSeparatorsColor;
  background-color: $white100Base;
  cursor: pointer;
  margin-top: 12px;
  overflow-y: auto;
  max-height: 236px;

  &:hover {
    border: 1px solid $primary20;
  }

  &.inputFocus {
    border: 1px solid $secondary40;
  }
}

.inputLabel {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $headerTextColor;
}

.inputContainer {
  margin-top: 24px;
}

.lineNumbers {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.lineNumbersContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;

  & > p {
    text-align: left;
    font-family: $fontFamilySourceCodePro;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $subtextColor;
  }
}

.lineNumbersBackground {
  background-color: $primary2;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  min-width: 48px;
  padding: 16px 0 16px 12px;
  height: auto;
  max-height: 1980px;
}

.textarea {
  font-family: $fontFamilyMonaco;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $headerTextColor;
  padding: 0;
  border: 0;
  margin: 16px 20px 16px 24px;
  outline: 0;
  resize: none;
  width: 100%;
  max-height: 1980px;
  overflow-y: hidden;
  cursor: pointer;
}
