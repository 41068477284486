@import 'styles/variables';

$initPosition: 3px;
$endPosition: -3px;
$dotSize: 6px;

.container {
  position: relative;
}

.dot {
  width: $dotSize;
  height: $dotSize;
  border-radius: 50%;
  position: absolute;
  animation: loader-animation 0.8s ease-in-out alternate infinite;
  margin: 0 auto 0;
  transform: translate(0, $initPosition);
}

.left {
  left: calc(50% - #{$dotSize / 2} - #{$dotSize * 2});
  animation-delay: 0.48s;
}

.middle {
  left: calc(50% - #{$dotSize / 2});
  animation-delay: 0.32s;
}

.right {
  left: calc(50% - #{$dotSize / 2} + #{$dotSize * 2});
  animation-delay: 0.16s;
}

.primary {
  .dot {
    background-color: $surfacesPrimary;
  }
}

.secondary {
  .dot {
    background-color: $secondary100Base;
  }
}

.warning {
  .dot {
    background-color: $red100Base;
  }
}

@keyframes loader-animation {
  0% {
    transform: translate(0, $initPosition);
  }
  100% {
    transform: translate(0, $endPosition);
  }
}
