@import 'styles/variables';

.container {
  display: flex;
  overflow-x: auto;
  width: 100%;
}

.value {
  margin-left: auto;
}

.total {
  font-weight: bold;
}

.dashedBorderBottom {
  border-bottom: 1px dashed $borderAndSeparatorsColor;
}

.fullWidth {
  width: 100%;
}
