@import 'styles/variables';

.spinner {
  position: absolute;
}

:export {
  fontFamily: $fontFamilyMonsterrat;
  labelColor: $primary100Base;
  iconColor: $secondary40;
  checkboxBackgroundColor: $surfacesLight;
  iconBorderColor: $borderAndSeparatorsColor;
}
