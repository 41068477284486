@import 'styles/variables';

$border: 1px solid rgba($primary20, 0.5);

.line {
  display: flex;
  padding: 12px 10px;
  border-top: $border;

  &:last-child {
    border-bottom: $border;
  }

  &:not(.disabled) {
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $secondary6;
      outline: none;
    }
  }
}

.name {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: $primary100Base;
}

.lastActiveDate {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $primary80;
}

.logo {
  width: 36px;
  height: 36px;
  margin-right: 12px;

  &.hidden {
    visibility: hidden;
  }
}

.organizationDefaultIcon {
  background-color: $surfacesLight;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  > svg {
    fill: #9295a2;
    fill-opacity: 0.4;
  }
}

.centred {
  display: flex;
  align-items: center;
}
