@import 'styles/variables';
@import 'styles/colorPalette';

.nameCell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: $boldFontWeight;
  color: $primary100Base;
}

.avatar {
  margin-right: 8px;
}
