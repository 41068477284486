@import 'styles/variables';

.name {
  font-family: $fontFamilyMonsterrat;
  font-size: 14px;
  font-weight: bold;
  color: $primary100Base;
  line-height: 20px;
}

.description {
  font-family: $fontFamilyMonsterrat;
  font-size: 12px;
  font-weight: 500;
  color: $primary80;
  line-height: 16px;
}
