@import 'styles/variables';
@import 'styles/mixins';

.page {
  width: 100%;
  height: 100%;
  font-family: $fontFamilyMonsterrat;
  margin-bottom: 50px;
  overflow-y: auto;
}

.caseHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on;

  &__title {
    display: flex;
    align-items: flex-end;

    h2 {
      @include headerTitle;
    }
  }
}

.caseInfo {
  margin-top: 8px;
}

.moduleStatus {
  padding: 3px 6px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $surfacesLight;
  border-radius: 2px;
  color: $defaultTextColor;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $tagFontSize;
  line-height: $tagLineHeight;
  letter-spacing: $defaultLetterSpacing;
  text-transform: uppercase;
}

.caseUpdateInfo {
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  & > p {
    margin-left: 10px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $defaultTextColor;
  }
}

.caseProcessInfo {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;

  & > a {
    color: $defaultLinkColor;

    &:hover {
      color: $defaultLinkHoverColor;
    }
  }
}

.caseContent {
  margin-top: 48px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-bottom: 18px;
}

.statusPassed {
  background-color: $green10;
  color: $green100Base;
}

.statusFailed {
  background-color: $red10;
  color: $red100Base;
}

.statusCompleted {
  background-color: $secondary10;
  color: $secondary100Base;
}

.statusError {
  background-color: $lightOrange10;
  color: $lightOrange100Base;
}

.statusNotRun {
  background-color: $surfacesLight;
  color: $primary80;
}

.moduleTable {
  margin-top: 7px;
  width: 100%;

  &__header {
    border-top: 1px solid $borderAndSeparatorsColor;
    padding: 14px 10px;
    display: flex;
    flex-direction: row;
    height: 42px;

    & > p {
      width: 50%;
      color: $defaultTextColor;
      font-family: $fontFamilyMonsterrat;
      font-weight: $boldFontWeight;
      font-style: normal;
      font-size: $smallFontSize;
      line-height: $smallLineHeight;
      font-feature-settings: 'tnum' on, 'lnum' on;
    }
  }

  &__row {
    @extend .moduleTable__header;

    & > p {
      font-weight: $normalFontWeight;
    }
  }

  &__titles {
    border-top: none;
    @extend .moduleTable__header;

    & > p {
      text-transform: uppercase;
      color: $subtextColor;
      font-size: $tableHeadFontSize;
      line-height: $tableHeadLineHeight;
      letter-spacing: $defaultLetterSpacing;
    }
  }
}

.expandButton {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover p {
    color: $defaultLinkHoverColor;
  }

  &:hover svg {
    stroke: $defaultLinkHoverColor;
  }

  & > p {
    font-style: normal;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-weight: $normalFontWeight;
    color: $defaultLinkColor;
    margin-right: 4px;
  }

  & > button {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      stroke: $defaultLinkColor;
      cursor: pointer;
      transform: rotate(180deg);
      transition: transform 0.25s ease-in-out;
    }

    &.isOpen {
      & > svg {
        transform: rotate(360deg);
      }
    }
  }
}

.moduleList {
  max-height: 0;
  height: auto;
  transition: max-height 0.5s ease-in-out, margin-top 0.5s ease-in-out;
  overflow: hidden;
  margin-top: 0;
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.variableContainer {
  margin-top: 47px;
  display: flex;
  flex-direction: row;

  & > div {
    min-width: calc(50% - 15px);

    &:first-child {
      margin-right: 30px;
    }
  }

  .textField {
    display: block;
  }
}

.lastModuleResult {
  border-bottom: 0;
}

.createdDateSkeleton {
  margin-left: 10px;
}

.headerLink {
  color: $defaultLinkColor;

  &:hover {
    color: $defaultLinkHoverColor;
  }
}
