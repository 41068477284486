@import 'styles/variables';
@import './LeftNavigationHeader/LeftNavigationHeader.module';

$leftNavigationWidthExpanded: 280px;
$leftNavigationWidthCollapsed: 72px;

$toggleButtonWidth: 16px;
$toggleButtonHeight: 60px;

.navBarContainerExpanded {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: $surfacesSidebar;
  width: $leftNavigationWidthExpanded;
  transition: width 0.5s ease-in-out;
  position: relative;
  z-index: 3;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: -$toggleButtonWidth;
    width: $toggleButtonWidth;
    min-height: 100%;
  }
  &:hover {
    .toggleButton {
      opacity: 1;
    }
  }
}

.navBarContainerCollapsed {
  @extend .navBarContainerExpanded;
  width: $leftNavigationWidthCollapsed;
}

.toggleImg {
  width: 6px;
  height: 10px;
  transition: all 0.25s ease-in-out;
}

.toggleImgOpened {
  @extend .toggleImg;
  transform: rotate(180deg);
}

.toggleButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -$toggleButtonWidth;
  top: calc(100% / 2 - #{$toggleButtonHeight / 2});
  background-color: $orange100Base;
  width: $toggleButtonWidth;
  height: $toggleButtonHeight;
  border-radius: 0 8px 8px 0;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  cursor: pointer;
  &:hover .imageTooltip {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.imageTooltip {
  max-width: 97px;
  min-width: 91px;
  max-height: 32px;
  height: 32px;
  background: $surfacesPrimary;
  border-radius: 4px;
  position: absolute;
  left: 24px;
  top: 14px;
  display: none;
  border: 1px solid $primary120;
}

.tooltipText {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $primary100Base;
}

.burgerMenuWrapper {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  box-shadow: 0px 8px 24px rgba(15, 35, 66, 0.2);
  z-index: 10;
}
