@import 'styles/variables';

.container {
  position: relative;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.chartContainer {
  display: flex;
  width: 100%;
}

.chartWrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.legend {
  margin-left: auto;
  width: 221px;
  display: flex;
  justify-content: space-between;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
}

.barGroup {
  cursor: pointer;
}

:export {
  fontFamily: $fontFamilyMonsterrat;
  fontSize: $smallFontSize;
  lineHeight: $smallLineHeight;
  fontColor: $primary40;
  fontWeight: $normalFontWeight;
}
