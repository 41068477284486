@import 'styles/variables';
@import 'styles/mixins';

.page {
  width: 100%;
  height: 100%;
  font-family: $fontFamilyMonsterrat;
  margin-bottom: 50px;
  overflow-y: auto;
}

.caseHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on;

  &__title {
    display: flex;
    align-items: flex-end;

    h2 {
      @include headerTitle;
    }
  }
}

.caseInfo {
  margin-top: 8px;
}

.moduleStatus {
  padding: 3px 6px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $surfacesLight;
  border-radius: 2px;
  color: $defaultTextColor;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $tagFontSize;
  line-height: $tagLineHeight;
  letter-spacing: $defaultLetterSpacing;
  text-transform: uppercase;
}

.caseUpdateInfo {
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  & > p {
    margin-left: 10px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $defaultTextColor;
  }
}

.caseProcessInfo {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
  & > a {
    color: $defaultLinkColor;
    &:hover {
      color: $defaultLinkHoverColor;
    }
  }
}

.caseContent {
  margin-top: 36px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.statusPassed {
  @extend .moduleStatus;
  background-color: $green10;
  color: $green100Base;
}

.statusFailed {
  @extend .moduleStatus;
  background-color: $red10;
  color: $red100Base;
}

.statusError {
  @extend .moduleStatus;
  background-color: $lightOrange10;
  color: $lightOrange100Base;
}

.historySearch {
  display: flex;
  align-items: center;
  padding-bottom: 14px;
  margin-top: 24px;
  border-bottom: 1px solid $borderAndSeparatorsColor;

  & input {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    border: none;
    outline: none;
    color: $headerTextColor;
    width: 100%;
    margin-left: 8px;

    &::placeholder {
      color: $subtextColor;
      opacity: 1;
    }
  }

  & > svg {
    width: 24px;
    height: 24px;
  }
}

.table {
  width: 100%;
  margin-top: 30px;
}

.ellipsis {
  min-width: 32px;
}
