@import 'styles/variables';

.pageContainer {
  min-height: 100%;
  width: 100%;
}

.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PageHeaderTitleContainer {
  display: flex;
  align-items: center;
}

.pageTitle {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  color: $headerTextColor;
  margin-right: 12px;
}

.pageDescription {
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
  margin-top: 12px;
}

.searchContainer {
  margin-right: 24px;
}

.tableContainer {
  margin-top: 24px;
}

.downloadImage {
  height: 24px;
  width: 24px;
  margin: 0 6px 0 -4px;

  & path {
    &:first-child {
      fill: $secondary100Base;
    }

    &:last-child {
      stroke: $secondary100Base;
    }
  }
}

.downloadButton {
  display: flex;
  align-items: center;
}

.activationsNoData {
  margin-top: 136px;
}

.activationsNoDataTitle {
  margin: 36px 0 12px
}

.filterContainer {
  margin-top: 24px;
  display: flex;
  align-items: center;
  padding-right: 12px;
}

.usersContainer {
  margin-right: auto;
}
