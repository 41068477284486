@import 'styles/variables';
@import 'styles/colorPalette';

.nameCell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-weight: $boldFontWeight;
  color: $primary100Base;
}

.loanAmountCell {
  justify-content: flex-end;
  font-weight: $boldFontWeight;
  color: $primary100Base;
}

.duplicateIcon {
  width: 24px;
  height: 24px;
  padding: 2px;
  stroke: $primary80;
}

.displayId {
  display: flex;
  align-items: center;
}

.nameLine{
  display: flex;
  gap: 8px;
}

.label{
  margin-top: 2px;
}

