@import 'styles/variables';

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 36px 55px 24px 55px;
}

.productNameInput {
  input,
  p {
    font-size: $h2FontSize;
    line-height: $h2LineHeight;
    padding-left: 0;
  }
}

.subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;

  & > p {
    margin-left: 8px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $primary80;
  }
}

.productTypeImage {
  & > svg {
    fill: $defaultTextColor;
  }
}

.rightSideSection {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.tag {
  padding: 12px 20px;
  text-transform: capitalize;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  letter-spacing: 0;
}

.moreButton {
  border-radius: 4px;
  border: 1px solid $primary10;
  margin-left: 12px;
  width: 44px;
  height: 44px;

  > button {
    width: 44px;
    height: 44px;
    opacity: 1;

    & circle {
      fill: $primary100Base;
    }
  }
}

.checkedIcon {
  margin-right: 6px;

  & > path {
    stroke: $green110;
  }
}
