@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.addStatusRuleButton {
  padding-top: 11px;
  margin: 0 20px;
  justify-content: normal;
}

.withBorderAddStatusRuleButton {
  border-top: 1px solid $borderAndSeparatorsColor;
}
