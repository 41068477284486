@import 'styles/mixins';

.container {
  padding: 36px 0 30px 0;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .headerTitle {
    @include headerTitle;
  }

  .buttonsContainer {
    display: flex;
    margin-left: auto;

    & button {
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
}

.addProductButton {
  width: 141px;
}

.subTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .showArchivedSwitchContainer {
    display: flex;

    & p {
      display: flex;
      align-items: center;
      margin-left: 4px;
      font-family: $fontFamilyMonsterrat;
      font-style: normal;
      font-weight: $normalFontWeight;
      font-size: $pFontSize;
      line-height: $pLineHeight;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: $headerTextColor;
    }
  }
}
