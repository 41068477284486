.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 24px;
}

.tabsList {
  padding: 0 55px;
}

