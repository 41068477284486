@import 'styles/variables';

.container {
  flex-grow: 1;
  padding: 36px 55px 50px 55px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.editableInput {
  input,
  p {
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
    padding-left: 0;
  }
}
