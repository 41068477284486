@import 'styles/variables';

.editIcon {
  margin-right: 6px;
}

.columnsContainer {
  display: grid;
  gap: 30px;
}

.column {
  align-self: start;
}

.variableNamesContainer {
  max-width: 40%;
}

.variableValuesContainer {
  width: 100%;
}

.infoPanelContentItemInner {
  padding: 14px 0;
}

.infoPanelRow {
  border-top: 1px solid $primary10;
}

.infoPanelLabel {
  color: $primary40;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  padding-right: 10px;
}

.infoPanelContentItem {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $defaultTextColor;
  text-align: left;
  padding-left: 10px;

  & a {
    color: $defaultTextColor;
    transition: 0.25s color ease-in-out;

    &:hover {
      color: $defaultLinkColor;
    }
  }
}

.existingApplicationDetailsContainer {
  padding-top: 24px;
}
