@import 'styles/variables';

.description {
  margin-bottom: 12px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.checkboxesContainer {
  display: flex;
  flex-direction: column;
}

.checkboxContainer {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.checkbox {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.saveChangesButton {
  min-width: 180px;
  margin-top: 36px;
}
