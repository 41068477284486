@import 'styles/variables';

.productTemplateDescription {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
  margin-top: 24px;
}

.productTemplatesContainer {
  margin-top: 12px;
}

.productTemplateItem {
  min-height: 80px;
}

.productStatusSection {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  & > p {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
    margin-bottom: 12px;
  }

  & > div {
    display: flex;
    flex-direction: row;
  }
}

.productStatusCheckboxContainer {
  margin-right: 0 !important;
}

.productStatusCheckbox {
  padding-right: 12px !important;
}

.questionIcon {
  margin-left: 8px;
}
