@import 'styles/variables';

.tooltip {
  > div {
    padding: 16px 24px;
  }
}

.info {
  font-weight: $normalFontWeight;
  color: $primary120;
  border: 1px solid $borderAndSeparatorsColor;
  box-sizing: border-box;
  border-radius: $borderRadiusDefault;
  padding: 0 2px;
  width: 24px;
  height: $pLineHeight;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $defaultLinkColor;
    background-color: $white100Base;
    border: transparent;
  }
}
