@import 'styles/variables';

.container {
  display: flex;
  flex-direction: row;
  padding: 24px 30px 10px 30px;
  background-color: $surfacesLight;
  border-radius: 6px;
  margin: 24px -30px 0 -30px;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:last-child) {
    margin-right: 30px;
  }
}
