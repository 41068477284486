@import 'styles/variables';

.permissionsPage {
  width: 100%;
  height: 100%;
}

.permissionsHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;

  &__title {
    display: flex;
    align-items: center;

    & h2 {
      color: $headerTextColor;
      font-size: $h2FontSize;
      line-height: $h2LineHeight;
      font-style: normal;
      font-weight: $boldFontWeight;
      font-family: $fontFamilyMonsterrat;
    }
  }
}

.tableHead {
  padding-right: 0;
}

.permissionsContent {
  padding: 36px 0 0 0;
}

.resetToDefaultsButton {
  display: flex;
  align-items: center;
  min-width: 180px;

  > svg {
    margin: 0 6px 0 -4px;

    path {
      stroke: $secondary100Base;
    }
  }
}

.tooltip {
  margin-left: 12px;
}
