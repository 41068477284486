@import 'styles/variables';

.header {
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
}

.noItemsContainer {
  margin-top: 60px;
}

.noItemsTitle {
  margin-top: 36px;
}

.noItemsButton {
  min-width: 180px;
  height: 52px;
  margin-top: 24px;
  padding: 16px 24px;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $secondary100Base;
  border: 1px solid $secondary100Base;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 4px;

  &:hover {
    background-color: $secondary10;
  }
}

.layoutHeader {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.filtersContainer {
  width: 100%;
  display: flex;
}

.userFilter {
  margin-left: 24px;
}

.noFiltersContainer {
  margin-top: 66px;
}

.noFiltersSubtitle {
  margin: 8px 0 24px 0;
}
