@import 'styles/variables';

.container {
  padding: 24px 30px 22px 30px;
  background-color: $surfacesLight;
  border-radius: 6px;
  margin: 0 -30px;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;

  > h3 {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $primary100Base;
  }

  .closeButton {
    width: 24px;
    height: 24px;
    cursor: pointer;

    & path {
      stroke: $defaultTextColor;
    }
  }
}

.titleLink {
  margin: -8px auto 0 16px;
  font-size: 14px;
  font-weight: $normalFontWeight;
  line-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $secondary100Base;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background-color: $white100Base;

    & > svg {
      stroke: $secondary100Base;
    }
  }
}
