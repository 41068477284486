@import 'styles/variables';

.selectControl {
  min-height: 52px;
  border: 1px solid $primary10;
  border-radius: 4px;
  padding: 4px 50px 0 0;
  cursor: pointer;
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:hover {
    border: 1px solid $primary20;
  }

  & .emptyInput {
    width: 0;
    min-height: 0;
    height: 0;
    padding: 0;
  }

  & input {
    max-width: 100%;
    border: 0;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $headerTextColor;
    outline: none;
    cursor: pointer;
    display: inline;
    min-height: 44px;
    margin-left: 4px;
    padding-left: 20px;
  }

  &.inputFocus {
    border: 1px solid $secondary40;
  }

  & p {
    width: 100%;
  }

  & svg {
    stroke: $headerTextColor;
  }

  & > svg {
    position: absolute;
    right: 21px;
  }
}

.disabledSelectControl {
  @extend .selectControl;
  background-color: $surfacesLight;
  cursor: default;
  &:hover {
    border: 1px solid $primary10;
  }
  & input {
    cursor: default;
    background-color: $surfacesLight;
  }
}

.inputContainer {
  padding-top: 24px;
}

.selectContainer {
  position: relative;
  outline: none;
  border-radius: 4px;
  margin-top: 8px;
}

.label {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
}

.errorMessage {
  color: $defaultErrorColor;
  margin-top: 4px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

.optionsContainer {
  display: flex;
  flex-wrap: wrap;
}

.labelsContainer {
  padding: 10px 0 14px 20px;
}

.labelsContainerWithInput {
  padding-bottom: 0;
}
