@import 'styles/variables';

.sizeCell {
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

.avatar {
  margin-right: 12px;
}

.extensionIcon {
  margin-right: 12px;
}
