@import 'styles/variables';

.progressBar {
  width: 120px;
  background-color: $primary20;
  border-radius: 2px;

  & div {
    background-color: $green100Base;
  }
}

.progressText {
  margin-left: 10px;
  color: $defaultTextColor;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
}
