@import 'styles/variables';

.infoPanelContentInner {
  display: flex;
  max-height: 132px;
  transition: max-height 0.4s ease-in-out;
  overflow: hidden;
}

.infoPanelColumnTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: $primary100Base;
  margin-bottom: 14px;
  display: flex;
}

.infoPanelContentBox {
  width: calc(100% / 3);
}

.infoPanelContentBox:not(:last-child) {
  margin-right: 30px;
}

.additionalContentOpen {
  max-height: 500px;
}

.teamMembers {
  padding: 8px 0;
  align-items: center;
}

.infoPanelContentItem {
  color: $defaultTextColor;
}

.duplicateIcon {
  stroke: $primary100Base;
}
