@import 'styles/variables';

.userLine {
  &:hover {
    background-color: $secondary6;
    button {
      opacity: 1;
    }
  }

  & p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__image {
    width: 28px;
    height: 28px;
    min-width: 28px;
    margin-right: 8px;
  }

  &__status {
    position: relative;

    & > p {
      width: auto;
      background-color: #e5f9f7;
      padding: 3px 6px;
      border-radius: 2px;
      font-size: $tagFontSize;
      line-height: $tagLineHeight;
      letter-spacing: $defaultLetterSpacing;
      font-weight: $boldFontWeight;
      color: #00c4ac;
      text-transform: uppercase;
    }
  }

  &__ellipsis {
    & > button {
      padding: 0;
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      outline: 0;
      cursor: pointer;
      opacity: 0;
      transition: 0.25s all ease-in-out;

      & > svg {
        & > circle {
          fill: $primary40;
          transition: fill 0.25s ease-in-out;
        }
      }

      &:hover {
        & > svg {
          & > circle {
            fill: $primary80;
          }
        }
      }
    }
  }

  &Active {
    border: 1px solid $secondary100Base;
    background: $secondary10;
    height: 43px;
  }
}

.statusInactive {
  & > p {
    background-color: rgba(146, 149, 162, 0.1);
    color: $subtextColor;
  }
}

.userRole {
  text-transform: capitalize;
}
