@import 'styles/variables';

.applicationFormConfigurationContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.configurationContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $surfacesLight;
}

.pageSettingsButton {
  min-width: 144px;
  margin-left: 12px;
  padding: 6px 8px;
  border-radius: 6px;

  &:not(:disabled):hover {
    background-color: $white100Base;
    color: $secondary100Base;
  }
}

.variableConfigurationFormTitle {
  min-height: 44px;
}
