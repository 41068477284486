@import 'styles/variables';

.pageTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-bottom: 16px;
}

.mainDataContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.chartContainer {
  display: flex;
  flex-direction: column;
  width: 77%;
}

.tableContainer {
  margin-top: 24px;
}

.chartWrapper {
  height: 100%;
  width: 100%;
}

.noItems {
  margin-top: 166px;
}

.notFound {
  margin-top: 120px;
}

.notFoundTitle {
  margin-top: 19px;
}

.notFoundSubtitle {
  margin-top: 8px;
}

.notFoundButton {
  margin-top: 24px;
}

.noItemsTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-top: 36px;
  margin-bottom: 10px;
}
