.table {
  margin-top: 34px;
}

.noItems {
  margin-top: 60px;

  .title {
    margin-top: 36px;
  }
}
