@import 'styles/variables';

.docuSignButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.addDocumentButton {
  display: flex;
  align-items: center;

  .dropdownImage {
    & path {
      stroke: $white100Base;
    }
  }
}

.tabHeader {
  margin-bottom: 8px;
}

.downloadAttachments {
  margin-top: -4px;
}
