@import 'styles/variables';

.noItemsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: $headerTextColor;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-family: $fontFamilyMonsterrat;
  margin-top: 18px;
}

.subtitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $primary80;
  margin: 8px 0 24px 0;
}

.buttonImage {
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkButton {
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultLinkColor;

  &:hover {
    color: $defaultLinkHoverColor;
  }
}
