@import 'styles/variables';

.intermediaryName {
  font-weight: $boldFontWeight;
  color: $headerTextColor;
}

.avatar {
  margin-right: 8px;
}
