@import 'styles/colorPalette';

.body {
  overflow-y: auto;
  max-height: 450px;
}

.disabledRow {
  color: $primary40;
}
