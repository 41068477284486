@import 'styles/variables';

.tooltipContent {
  min-height: 92px;
  min-width: 180px;
  padding: 16px 30px 16px 24px;
}

.tooltipDate {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary100Base;
}

.tooltipInfo {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  margin-top: 4px;
  color: $primary40;
}
