@import 'styles/variables';
@import 'styles/mixins';

.container {
  width: 980px;
  height: 100%;

  padding: 55px;
}

.tabsSwitch {
  margin: 18px 30px 0 30px;
}

.tab {
  & label {
    margin-right: 36px;
  }
}

.buttonTab {
  & label {
    margin-right: 30px;
  }
}

.tabContainer {
  padding: 36px 25px 0 25px;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  h2 {
    @include headerTitle;
  }
}

.closeButton {
  cursor: pointer;

  & path {
    stroke: $defaultTextColor;
  }
}

.actionContainer {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;

  .cancelButton {
    margin-right: auto;
    margin-left: 12px;
  }

  .removeButton {
    margin-right: 0;
  }
}
