.container {
  height: 435px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.chartRowSkeleton {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(146, 149, 162, 0.4);
  margin-bottom: 64px;
}

.bottomAxis {
  display: flex;
  justify-content: space-between;
  padding: 0 38px;
  margin-top: -52px;
}
