@import 'styles/variables';

.description {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
}

.closeWindowButton {
  min-width: 180px;
  margin-top: 36px;
}
