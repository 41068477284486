@import 'styles/variables';

.configurationFormContainer {
  padding: 36px 55px 60px 55px;
}

.configurationFormTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.configurationFormHeader {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  color: $headerTextColor;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.headerContainer {
  display: flex;
  align-items: center;
}
