@import 'colorPalette';

$fontFamilyMonsterrat: 'Montserrat', 'sans-serif';
$fontFamilySourceCodePro: 'Source Code Pro';
$fontFamilyMonaco: 'Monaco', 'monospace', 'courier';

$h1FontSize: 48px;
$h2FontSize: 36px;
$h3FontSize: 28px;
$h4FontSize: 22px;
$h5FontSize: 18px;
$h6FontSize: 16px;
$h1LineHeight: 56px;
$h2LineHeight: 44px;
$h3LineHeight: 36px;
$h4LineHeight: 28px;
$h5LineHeight: 24px;
$h6LineHeight: 22px;

$pFontSize: 14px;
$pLineHeight: 20px;
$smallFontSize: 12px;
$smallLineHeight: 16px;
$tableHeadFontSize: 9px;
$tableHeadLineHeight: 12px;
$tagFontSize: 9px;
$tagLineHeight: 10px;
$menuHeaderFontSize: 10px;
$menuHeaderLineHeight: 12px;

$defaultLetterSpacing: 0.1em;

$seminormalFontWeight: 400;
$normalFontWeight: 500;
$semiboldFontWeight: 600;
$boldFontWeight: 700;

$headerTextColor: $primary100Base;
$defaultTextColor: $primary80;
$subtextColor: $primary40;
$captionColor: $primary20;
$lightCaptionColor: $primary10;
$placeholderTextColor: $primary40;

$contrastSurfacesTextColor: $white100Base;
$contrastSurfacesIconColor: $white100Base;
$contrastSurfacesAciveTextColor: $orange100Base;
$contrastSurfacesActiveIconColor: $orange100Base;

$defaultErrorColor: $red100Base;
$defaultLinkColor: $secondary100Base;
$defaultLinkHoverColor: $secondary120;

$borderAndSeparatorsColor: $primary10;

$inputHoverBorderColor: $primary40;

$borderRadiusDefault: 4px;
$bottomPaddingDefault: 60px;
