@import 'styles/variables';
@import 'styles/mixins';

.input {
  width: 100%;
}

.inputContainer {
  width: auto;
  display: flex;
  flex-flow: row wrap;
  margin: 0 55px;
}

.buttonContainer {
  margin: 36px 55px 55px;
}

.table {
  margin-top: 24px;
  width: 100%;
}

.rowWithError {
  font-weight: $boldFontWeight;
  color: $defaultErrorColor;
}

.iconContainer {
  position: relative;
}

.alertIcon {
  position: absolute;
  right: 0;
  top: 10px;
  fill: $defaultErrorColor;
}

.bottomError {
  padding: 24px 55px;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  width: 100%;
  background-color: $red10;
  color: $defaultErrorColor;
}
