@import 'styles/variables';

.record {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 82px;
  padding: 18px 0 18px 60px;
  border-top: 1px solid $borderAndSeparatorsColor;

  &:last-child {
    border-bottom: 1px solid $borderAndSeparatorsColor;
  }

  &:hover {
    .description {
      white-space: normal;
      overflow: visible;
    }
  }
}

.recordContent {
  width: 83%;
}

.recordInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & > p {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $defaultTextColor;
    margin-left: 8px;
  }
}

.icon {
  position: absolute;
  left: 0;
}

.contentTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.contentDescription {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
}
