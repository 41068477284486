@import 'styles/mixins';

.container {
  background-color: $surfacesPrimary;
  padding: 36px 55px 0 55px;
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .headerTitle {
    @include headerTitle;
  }

  .downloadApiTemplateButton {
    width: 294px;
    height: 52px;
    margin-left: auto;
  }
}

.subTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
  margin: 12px 0 24px 0;
}

.tab {
  label {
    margin-right: 36px;
  }
}
