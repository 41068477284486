@import 'styles/variables';

.editIcon {
  margin-right: 6px;
}

.columnsContainer {
  display: flex;
  flex-direction: row;

  & > div {
    width: 50%;

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}

.leftColumnItem {
  align-items: flex-start;

  & > div:first-child {
    min-width: 99px;
  }
}

.rightColumnItem {
  & > div:first-child {
    min-width: 129px;
  }
}

.rightColumn {
  & > div:last-child {
    border-bottom: 1px solid $borderAndSeparatorsColor;
  }
}
