.container {
  display: flex;
  width: 200px;
}

.inputZeroMarginTop {
  margin-top: 0;
}

.addMargin {
  margin-left: 12px;
}

.changeHeight {
  max-height: 44px;
  margin-top: 0;
}
