@import 'styles/variables';

.steps {
  display: flex;
  width: 100%;

  > div {
    display: flex;
    align-items: center;
  }
}

.arrow {
  margin: 0 10px;

  & > path {
    stroke: $primary20;
  }
}
