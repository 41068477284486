@import 'styles/variables';
@import 'styles/colorPalette';

$animationTimeout: 250;
$initialScale: 0.9;
$targetScale: 1;
$initialOpacity: 0.5;
$targetOpacity: 1;

.popupAppear {
  .popupMain {
    transform: scale($initialScale);
    opacity: $initialOpacity;
  }

  &.popupCore {
    opacity: 0;
  }
}

.popupAppearActive {
  .popupMain {
    transform: scale($targetScale);
    opacity: $targetOpacity;
    transition: all #{$animationTimeout}ms ease;
  }

  &.popupCore {
    opacity: $targetOpacity;
    transition: all #{$animationTimeout}ms ease;
  }
}

.popupAppearDone {
  .popupMain {
    transform: scale($targetScale);
    opacity: $targetOpacity;
  }

  &.popupCore {
    opacity: $targetOpacity;
  }
}

.popupMain {
  background: $surfacesPrimary;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
  font-family: $fontFamilyMonsterrat;
  overflow-x: hidden;
  max-height: 90%;
  max-width: 710px;
  min-width: 710px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupCore {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(146, 149, 162, 0.4);
}

.warningText {
  padding: 24px 55px;
  background-color: $red10;
  color: $red100Base;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

:export {
  animationTimeout: $animationTimeout;
}
