@import 'styles/variables';

.popupContent {
  padding: 0 55px 48px;
}

.popupContentWithTopMargin {
  margin-top: 24px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(146, 149, 162, 0.4);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
