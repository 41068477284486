@import 'styles/variables';

.tab {
  display: flex;
  flex: 0 0 auto;
}

.defaultTabContent {
  position: relative;
  display: flex;
  flex: 0 0 auto;

  & > label {
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $subtextColor;
    cursor: pointer;
    margin-right: 24px;
  }

  &.checked > label {
    color: $headerTextColor;
    border-bottom: 2px solid $orange100Base;
    padding-bottom: 9px;
  }

  & > input[type='radio'] {
    display: none;
  }

  &:not(.checked):hover .closeImage {
    display: flex;
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-y: hidden;
  overflow-x: auto;
}

.addTabButton {
  height: 24px;
  border-radius: 4px;
  padding-right: 6px;

  > button {
    height: 20px;
    padding: 0;

    &:active {
      padding: 0;
    }

    &:hover {
      color: $secondary100Base;
    }

    > svg {
      stroke: $secondary100Base;
    }
  }

  &:hover {
    background-color: $secondary10;
  }
}

.closeImage {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  cursor: pointer;

  & > svg {
    stroke: $primary80;
  }
}

.borderContainer {
  position: relative;
}

.border {
  position: absolute;
  top: calc(100% - 2px);
  height: 2px;
  width: 100%;
  background-color: $borderAndSeparatorsColor;
  z-index: -1;
}
