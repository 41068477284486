@import 'styles/variables';
@import 'styles/mixins';

.processingPage {
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
  overflow-y: auto;
}

.processingHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on;

  &__title {
    display: flex;
    align-items: flex-end;

    h2 {
      @include headerTitle;
    }
  }

  &__guide {
    margin-left: 16px;

    a {
      display: flex;
      align-items: center;
      @include headerGuideText;
    }
  }
}

.processingDescription {
  margin: 8px 0 24px 0;
  position: relative;
  display: flex;
  flex-direction: column;

  & > p {
    min-height: $pLineHeight;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $defaultTextColor;
    border: 0;
    outline: none;
    resize: none;
    padding: 0;
    overflow-y: hidden;
  }
}

.processingSwitch {
  padding-bottom: 10px;
  border-bottom: 1px solid $borderAndSeparatorsColor;

  & > label {
    font-style: normal;
    font-weight: $semiboldFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $subtextColor;
    cursor: pointer;
    padding-bottom: 10px;
    margin-right: 24px;
  }

  & > input[type='radio'] {
    display: none;

    &:checked {
      + label {
        color: $headerTextColor;
        border-bottom: 2px solid $orange100Base;
      }
    }
  }
}

.processingContent {
  margin-top: 36px;
}

.input {
  flex: 1 0 calc(50% - 30px);

  &:nth-child(odd) {
    margin-right: 30px;
    max-width: calc(50% - 30px);
  }
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}
