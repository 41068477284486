@import 'styles/variables';
@import 'styles/colorPalette';

.list {
  display: flex;
}

.userAvatar {
  background-color: $white100Base;
  border-radius: 50%;
  padding: 2px;

  &:not(:first-child) {
    margin-left: -10px;
  }
}
