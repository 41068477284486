@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-bottom: 16px;
}

.description {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultTextColor;
}

.filtersRow {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
