@import 'styles/variables';

.pageContent {
  padding: 22px 25px 50px 25px;
}

.skeletonHeader {
  display: flex;
  flex-direction: column;
}

.skeletonTitle {
  display: flex;
  flex-direction: column;
  padding: 14px 30px 8px;

  & > div {
    &:first-child {
      display: flex;
      justify-content: space-between;
    }
    & > div {
      display: flex;
      gap: 12px;
    }
  }
  
}

.tabsContainer {
  margin: 0 25px;
  padding: 36px 0;
  border-top: solid 1px $borderAndSeparatorsColor;
}

