@import 'styles/variables';

.requirementsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
  height: 80px;
}

.requirement {
  display: flex;
  align-items: center;
}

.requirementText {
  margin-left: 12px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $defaultTextColor;
  font-weight: $normalFontWeight;
}

.buttonsContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 36px;
}
