@import 'styles/variables';

.container {
  display: flex;
  flex-direction: column;
  width: 220px;
}

.content {
  display: flex;
}

.amount {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary100Base;
  height: 100%;
  padding-right: 38px;
  padding-top: 13px;
  padding-left: 10px;
  border-bottom: 1px solid $borderAndSeparatorsColor;
  min-width: 120px;
}

.rows {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  margin-left: 110px;
}

.leftPaddingZero {
  padding-left: 0;
}

.dashedBorderBottom {
  border-bottom: 1px dashed $borderAndSeparatorsColor;
}

.total {
  font-weight: bold;
}
