@import 'styles/variables';

.companyInformationHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: $fontFamilyMonsterrat;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;

  &__title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    & h2 {
      color: $headerTextColor;
      font-size: $h2FontSize;
      line-height: $h2LineHeight;
      font-style: normal;
      font-weight: $boldFontWeight;
      font-family: $fontFamilyMonsterrat;
    }
  }
  &__info {
    display: flex;
    margin-top: 36px;

    .logo {
      max-height: 52px;
      max-width: 163px;
      overflow: hidden;
      margin-right: 20px;

      & img {
        max-height: 100%;
        min-width: 100%;
      }
    }

    & .companyName {
      & h3 {
        font-family: $fontFamilyMonsterrat;
        font-weight: $boldFontWeight;
        font-size: $h4FontSize;
        line-height: $h4LineHeight;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: $headerTextColor;
      }

      & .uploadLogoButton {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        font-family: $fontFamilyMonsterrat;
        font-style: normal;
        font-weight: $normalFontWeight;
        font-size: $pFontSize;
        line-height: $pLineHeight;
        color: $defaultLinkColor;
        cursor: pointer;
        &:hover {
          color: $defaultLinkHoverColor;
        }
      }
    }
  }
}

.companyInformationBody {
  &__mainInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 12px;
  }

  &__infoItem {
    width: 48%;
    box-sizing: border-box;
  }

  &__statePostalInfo {
    @extend .companyInformationBody__infoItem;
    display: flex;
    justify-content: space-between;
  }

  &__stateInfo {
    width: 47%;
    box-sizing: border-box;
  }

  &__postalInfo {
    @extend .companyInformationBody__stateInfo;
  }

  &__billingContent {
    @extend .companyInformationBody__mainInfo;
    padding: 0;
  }

  &__billingTitle {
    & h4 {
      font-family: $fontFamilyMonsterrat;
      font-style: normal;
      font-weight: $boldFontWeight;
      font-size: $h4FontSize;
      line-height: $h4LineHeight;
      color: $headerTextColor;
    }
  }

  &__save {
    padding-top: 36px;
  }
}

.userAvatar {
  margin-right: 8px;
}
