@import 'styles/variables';
@import 'components/TextInput/TextInput.module';

.container {
  position: relative;
  width: 100%;
}

.calendarInputOverlay {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  pointer-events: none;
}

.calendarIconContainer {
  flex: 1 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: auto;
  cursor: pointer;
}

.calendarIconLeftOffset {
  @include inputBox;
  font-size: $pFontSize;
  flex: 0 0 auto;
  visibility: hidden;
}

.calendarIcon {
  margin-right: 20px;
}

.loader {
  right: 50px;
}
