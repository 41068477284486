@import 'styles/variables';
@import 'styles/mixins';

.input {
  width: 100%;
}

.inputContainer {
  width: auto;
  display: flex;
  flex-flow: row wrap;
}

.buttonContainer {
  margin-top: 36px;
}
